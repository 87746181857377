/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('SettingsController', ['$state', 'loginService', 'loggerService', function ($state, loginService, loggerService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.footer = false;
    vm.sidebar = false;
    vm.currentUser = loginService.getSessionData();
    /* BINDABLE : METHODS*/

    vm.hideFooter = hideFooter;
    vm.hideSibeBar = hideSibeBar;
    vm.datadogLogs = datadogLogs;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function hideFooter(hide) {
      vm.footer = hide;
    }

    function hideSibeBar(hide) {
      vm.sidebar = hide;
    }

    function datadogLogs() {
      var providerAccountName = vm.currentUser.accountName;
      var providerAccountId = vm.currentUser.accountId;
      var name = 'Invoice Tab Clicked - Retrieve the invoices Information for provider account:' + providerAccountName;
      loggerService.info(name, {
        name: providerAccountName,
        id: providerAccountId
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($state.current.name === 'invoices') {
        vm.hideFooter(true);
      }

      if ($state.current.name === 'defaultMediaPrefs' || $state.current.name === 'integrations') {
        vm.hideSibeBar(true);
      }
    }
  }]);
})();